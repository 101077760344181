import React, { Component } from "react";
import { HashRouter, Route, Switch } from "react-router-dom";
import "./scss/style.scss";
import * as firebase from "firebase";
import globalUtils from "./views/globalUntil";
import md5 from "md5";
// require('dotenv').config()
const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const TheLayout = React.lazy(() => import("./containers/TheLayout"));

// Pages
const Login = React.lazy(() => import("./views/pages/login/Login"));
const Register = React.lazy(() => import("./views/pages/register/Register"));
const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));
const Page500 = React.lazy(() => import("./views/pages/page500/Page500"));
const firebaseConfig = {
  apiKey: "AIzaSyDajHxBbTnon02d9jygKvdcgEFuImbxTjM",
  authDomain: "compass-fengshui.firebaseapp.com",
  databaseURL: "https://compass-fengshui.firebaseio.com",
  projectId: "compass-fengshui",
  storageBucket: "compass-fengshui.appspot.com",
  messagingSenderId: "510331775933",
  appId: "1:510331775933:web:040d913a9c673bbb3c80d4",
  measurementId: "G-E610HQSZDN",
};
var database = {
  apiKey: "AIzaSyAqDXUKgULXkPDQIs2Le_beT6R0_JcmyY8",
  authDomain: "tmfs-yi-jing.firebaseapp.com",
  databaseURL: "https://tmfs-yi-jing.firebaseio.com",
  projectId: "tmfs-yi-jing",
  storageBucket: "tmfs-yi-jing.appspot.com",
  messagingSenderId: "331042421722",
  appId: "1:331042421722:web:862f72fc6b6c3ed5419ee3",
};
firebase.initializeApp(firebaseConfig);

var otherProject = firebase.initializeApp(database, "database");
var otherFirestore = otherProject.firestore();
class App extends Component {
  componentDidMount() {
    const mdfive = md5("CompassFengshuiWebSiteHitekTuongMinh");
    const isCustomer = localStorage.getItem(mdfive);

    if (isCustomer) {
      globalUtils.isCustomer = true;
      let expire = new Date(isCustomer);

      if (expire <= new Date()) {
        this.setState({ isCustomer: false });
        localStorage.removeItem("5aef5e583207818697f5c2a57d13bb20");
      }
    }
    this.AuthDB()
  }
   AuthDB (){

    const auth = firebase.app("database").auth();
    auth.setPersistence(firebase.auth.Auth.Persistence.SESSION)
    .then(() => {
      return auth.signInWithEmailAndPassword( process.env.REACT_APP_EMAIL_AUTH, process.env.REACT_APP_PASS_AUTH);
    })
    .catch((error) => {
      // Handle Errors here.
      const errorCode = error.code;
      const errorMessage = error.message;
    });

  }



  render() {
    return (
      <HashRouter>
        <React.Suspense fallback={loading}>
          <Switch>
            <Route
              exact
              path="/login"
              name="Login Page"
              render={(props) => <Login {...props} />}
            />
            <Route
              exact
              path="/register"
              name="Register Page"
              render={(props) => <Register {...props} />}
            />
            <Route
              exact
              path="/404"
              name="Page 404"
              render={(props) => <Page404 {...props} />}
            />
            <Route
              exact
              path="/500"
              name="Page 500"
              render={(props) => <Page500 {...props} />}
            />
            <Route
              path="/"
              name="Home"
              render={(props) => <TheLayout {...props} />}
            />
          </Switch>
        </React.Suspense>
      </HashRouter>
    );
  }
}

export default App;
